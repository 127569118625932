<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">{{ yearNow }}年-偏護菁英分發志願選填</h5>
      <div class="dtc-search1">
        <b-input-group prepend="姓名">
          <b-input v-model.trim="name" placeholder="姓名" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="id"
            placeholder="身分證字號"
            readonly
          ></b-input>
        </b-input-group>

        <div style="color:red;margin-top:9px">
          {{ startTime }}~{{ endTime }}
          開放志願選填，請於期限內完成志願選填
        </div>
      </div>
    </section>
    <div class="main-content">
      <div class="sectoion-left">
        <h5 class="big-title2">
          分發服務醫院志願選填
        </h5>
        <h5 class="big-title3">志願順序</h5>
        <div v-for="(item, i) in items" :key="i" class="mb-1 priorityNum">
          <b-input-group :prepend="`${i + 1}`" class="priority">
            <b-form-select
              v-model="item.value"
              :options="hospitalOptions"
              @change="updateItem"
              :disabled="isAlreadyComfirm || !isCanEditTime"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="left-button">
          <b-button
            variant="success"
            size="md"
            class="mr-3"
            @click="tempSave"
            :disabled="isAlreadyComfirm || !isCanEditTime"
            >暫存</b-button
          >
          <b-button
            variant="danger"
            size="md"
            @click="showConfirm"
            :disabled="isAlreadyComfirm || !isCanEditTime"
            >確認送出</b-button
          >
        </div>
      </div>
      <div class="sectoion-right">
        <h5 class="big-title2">分發服務醫院資訊</h5>
        <header class="dtc-grid-header my-dark">
          <div
            v-for="(item, i) in headers"
            :key="`headers${i}`"
            :title="item"
            @click="sort(item)"
          >
            {{ item }}
            <span v-show="item.sortDesc === null">
              <i class="fas fa-sort"></i>
            </span>
            <span v-show="item.sortDesc === false">
              <i class="fas fa-sort-up"></i>
            </span>
            <span v-show="item.sortDesc">
              <i class="fas fa-sort-down"></i>
            </span>
          </div>
        </header>
        <main
          class="dtc-grid-header2"
          v-for="(item, i) in hospitalDatas"
          :key="i"
          style="color:#39312E;"
          :style="
            i % 2 == 0
              ? 'background-color:#EDEDED ;'
              : 'background-color: #DDDDDD;'
          "
        >
          <div :title="item.HospitalName">
            {{ item.HospitalName || "" }}
          </div>
          <div :title="item.CategoryName">
            {{ item.CategoryName || "" }}
          </div>
          <div :title="item.CountryName">
            {{ item.CountryName || "" }}
          </div>
          <div :title="item.NeedStudent">
            {{ item.NeedStudent || "" }}
          </div>
          <div :title="item.Kind" :style="item.Kind == '否' ? 'color:red' : ''">
            {{ item.Kind || "" }}
          </div>
          <div :title="item.Remark">
            {{ item.Remark || "" }}
          </div>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";

const headers = [
  "醫院名稱",
  "醫院層級",
  "縣市別",
  "名額",
  "三年內未考取護理師資格,醫院將以實習護士或照服員聘用意願",
  "備註",
];

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      selected: "",
      hospitalOptions: [],
      hospitalDatas: [],
      yearNow: new Date().getFullYear() - 1911,
      isAlreadyComfirm: false,
      startTime: null,
      endTime: null,
      name: "",
      id: "",
      usStartTime: null,
      usEndTime: null,
    };
  },
  components: {},
  computed: {
    isCanEditTime() {
      return (
        Boolean(Date.now() > Date.parse(`${this.usStartTime}`)) &&
        Boolean(Date.now() < Date.parse(`${this.usEndTime}`))
      );
    },
  },

  methods: {
    async showConfirm() {
      let isExistNull = this.items.find((s) => !s.value) ? true : false;

      if (isExistNull) {
        this.$bvToast.toast(`儲存失敗，請確實填寫選填全部志願`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      let result = await Swal.fire({
        title: "本人已確認醫院志願清單正確無誤且確認送出",
        text: "(注意! 按下此按鈕後，不得以任何理由修改)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確認送出",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        let arr = this.items.map((s) => (Boolean(s.value) ? s.value : ""));
        const obj = {
          YearTerm: `${this.yearNow}`,
          Identifier: this.id,
          isActiver: true,
          Hospitals: [...arr],
        };
        await window.axios.post("OrderHospital/SetOrders", obj);
        Swal.fire("醫院志願清單", "已成功永久送出", "success");
      }
    },
    async tempSave() {
      let arr = this.items.map((s) => (Boolean(s.value) ? s.value : ""));
      const obj = {
        YearTerm: `${this.yearNow}`,
        Identifier: this.id,
        isActiver: false,
        Hospitals: [...arr],
      };
      try {
        await window.axios.post("OrderHospital/SetOrders", obj);
        this.$bvToast.toast(`暫存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getHospitalData() {
      const url = `Recruit/GetYearHospitals?sYearTerm=${this.yearNow}`;

      try {
        let { Items, Count } = await window.axios.get(url);
        this.hospitalDatas = Items.map((s) => {
          s.text = s.HospitalName;
          s.value = s.HospitalCode;
          return s;
        });
        this.hospitalOptions = Items.map((s) => {
          s.text = s.HospitalName;
          s.value = s.HospitalCode;
          return s;
        });

        this.hospitalOptions.unshift({ value: null, text: "請選擇" });
        this.setPriorityData(Items.length);
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async setPriorityData(length) {
      const url = `OrderHospital/GetOrders?sYearTerm=${this.yearNow}&sIdentifier=${this.id}`;
      let { Hospitals, isActiver } = await window.axios.get(url);
      let arr = [];
      arr = new Array(length).fill().map((s, i) => ({
        value: Hospitals.find((k) => +k.Priority == i + 1)
          ? Hospitals.find((k) => +k.Priority == i + 1).HospitalCode
          : null,
        priority: i + 1,
      }));
      this.isAlreadyComfirm = isActiver;
      this.items = arr;
      await this.updateItem();
    },
    updateItem() {
      this.hospitalOptions = this.hospitalOptions.map((s) => ({
        value: s.value,
        text: s.text,

        disabled:
          s.value == null
            ? false
            : this.items.find((k) => s.value == k.value)
            ? true
            : false,
      }));
    },
    async getOpenTime() {
      const url = `Recruit/GetHead?sYearTerm=${this.yearNow}`;
      try {
        const { isActiver, OstartDate, OendDate } = await window.axios.get(url);

        this.startTime = Boolean(OstartDate)
          ? this.$twDate2(moment(OstartDate).format("YYYY-MM-DD"))
          : null;

        this.endTime = Boolean(OendDate)
          ? this.$twDate2(moment(OendDate).format("YYYY-MM-DD"))
          : null;
        this.usStartTime = Boolean(OstartDate)
          ? moment(OstartDate).format("YYYY-MM-DD")
          : null;

        this.usEndTime = Boolean(OendDate)
          ? moment(OendDate).format("YYYY-MM-DD")
          : null;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.name = Boolean(sessionStorage.fullname)
      ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
      : "";
    this.id = Boolean(sessionStorage.igfjh)
      ? `${window.sessionStorage.igfjh}`.replace(/\"/g, "")
      : "";

    await this.getHospitalData();
    await this.getOpenTime();
  },

  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 3060px;
  display: grid;
  grid-template-columns: 305px 140px 140px 120px 450px 300px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 41px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 11px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 283px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 94px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep.btn-primary {
  background: #165b9b;
  border-color: #165b9b;
}
.big-title2 {
  font-weight: 600;
}

.main-content {
  display: grid;
  grid-template-columns: 400px repeat(1, 1fr);
  grid-column-gap: 50px;
}
div.sectoion-left
  > div.priorityNum
  > div.priority
  > div.input-group-prepend
  > div.input-group-text {
  background: #fcc41b;
  width: 50px;
  color: rgb(51, 51, 51);
}
.sectoion-left {
  padding-right: 50px;
  border-right: 1px dashed #cacaca;
  .big-title3 {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    background: #fcc41b;
    margin: 0;
    border-radius: 5px 5px 0 0;
    color: rgb(51, 51, 51);
    padding: 5px;
  }
  .left-button {
    margin-top: 10px;
    grid-column: 1/-1;
    text-align: center;
  }
}
::v-deepoption:disabled {
  // background: red;
  color: red;
}
</style>
